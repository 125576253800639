/* eslint-disable*/
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/ExpansionPanel'
import MuiAccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiAccordionDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup'
import { Box, Button, CircularProgress, Popover } from '@material-ui/core'
import { apiConfig } from './Lib/config'
import { showNotification } from '../../app/Notifications/NotificationActionCreator'
import { useDispatch } from 'react-redux'
import { isTPlus } from '../../app/Auth/Auth'

export const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '0 0 6px 6px',
    marginBottom: 5,
    borderBottom: 0,
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '16px 0 5px 0',
    },
  },
  expanded: {},
})(MuiAccordion)

export const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 35,
    },
  },
  content: {
    margin: '2px 0',
    '&$expanded': {
      margin: '2px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

export const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))(MuiAccordionDetails)

export default function EnvSettings({
  toggleGrid = () => {},
  togglePivot = () => {},
  changeLight = () => {},
  ...props
}) {
  const [state, setState] = React.useState({
    grid: true,
    pivot: true,
    light: true,
  })
  const { grid, pivot, light } = state

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  React.useEffect(() => {
    toggleGrid(grid)
  }, [grid])
  React.useEffect(() => {
    togglePivot(pivot)
  }, [pivot])
  React.useEffect(() => {
    changeLight(light)
  }, [light])

  return (
    <Accordion square expanded>
      <AccordionSummary>
        <Typography variant="body1">Environment Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={grid} onChange={handleChange} name="grid" />
            }
            labelPlacement="start"
            label="Show Grid"
          />
          <FormControlLabel
            control={
              <Switch checked={pivot} onChange={handleChange} name="pivot" />
            }
            labelPlacement="start"
            label="Show Pivot"
          />
          <FormControlLabel
            control={
              <Switch checked={light} onChange={handleChange} name="light" />
            }
            labelPlacement="start"
            label="Neutral Light"
          />
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  )
}

export function Dimensions({ features }) {
  return (
    <Accordion square expanded style={{ height: '100px' }}>
      <AccordionSummary>
        <Typography variant="body1" style={{ color: '#cc0000' }}>
          Dimensions (Overall)
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {features?.map((feature) => {
          if (feature.name.includes('Dimensions')) {
            return feature.value
          } else {
            return <></>
          }
        })}
      </AccordionDetails>
    </Accordion>
  )
}

